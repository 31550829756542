







































import { Component, Vue } from 'vue-property-decorator';
import DownloadTheAppCallToAction from "@/components/DownloadTheAppCallToAction.vue";
import DetailedInformation from "@/components/utils/DetailedInformation.vue";

export enum SectionEnum {
    time = 0,
    wallet = 1,
    target = 2
}

export const sectionEnums = [SectionEnum.time, SectionEnum.wallet, SectionEnum.target];

@Component({
    components: { DetailedInformation, DownloadTheAppCallToAction }
})
export default class DownloadTheApp extends Vue {
    private isOnLoop = true;
    public selectedSection: SectionEnum = SectionEnum.time;

    public created(): void{
        setInterval(() => {
            const index = (this.selectedSection + 1) % sectionEnums.length;
            this.selectSection(sectionEnums[index]);
        }, 3000)
    }

    public selectSection(section: SectionEnum): void {
        this.selectedSection = section;
        this.isOnLoop = false;
    }

    public isSelected(section: SectionEnum): boolean {
        return section === this.selectedSection;
    }

    public get image(): string {
        const imgString = {
            [SectionEnum.time]: 'login_page',
            [SectionEnum.wallet]: 'main_page',
            [SectionEnum.target]: 'deposit_page',
        }[this.selectedSection];

        return require(`../assets/images/${imgString}.png`);
    }
}
