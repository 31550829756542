















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { CoinData, CoinEnum, formatAmount } from "@/models/coin";
import CoinInitialsAndRate from "@/components/CoinInitialsAndRate.vue";
import { Amount } from "@/models/Quote";

@Component({
    name: 'quoteItem',
    components: { CoinInitialsAndRate }
})
export default class QuoteItem extends Vue {
    @Prop()
    public coinData!: CoinData;

    @Getter('getPrice')
    private getPrice!: (coin: CoinEnum) => Amount | undefined;

    public get iconImage(): string{
        return require(`../assets/icons/${this.coinData.icon}`)
    }

    public get price(): string | undefined{
        const result = this.getPrice(this.coinData.coin);
        if(!result)
            return;

        return formatAmount(result);
    }
}
