






import { Component, Vue } from "vue-property-decorator";

@Component({
    name: 'instagramIcon'
})
export default class InstagramIcon extends Vue {

}
