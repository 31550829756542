import { Amount } from '@/models/Quote';

export enum CoinEnum {
    real = 1,
    usdt = 2,
    btc = 3,
    usdc = 4,
    eth = 5,
    usd = 7
}

export interface CoinData {
    coin: CoinEnum;
    icon: string;
    color: string;
    label: string;
    initials: string;
}

export const coins: CoinData[] = [
    {
        coin: CoinEnum.btc,
        icon: 'btc.png',
        color: '#FFA800',
        label: 'Bitcoin',
        initials: 'BTC',
    },
    {
        coin: CoinEnum.eth,
        icon: 'eth.png',
        color: 'black',
        label: 'Ethereum',
        initials: 'ETH',
    },
    {
        coin: CoinEnum.usdc,
        icon: 'usdc.svg',
        color: '#647C95',
        label: 'USD Coin',
        initials: 'USDC',
    },
    {
        coin: CoinEnum.usdt,
        icon: 'tether.svg',
        color: 'green',
        label: 'Tether',
        initials: 'USDT',
    },
];

export enum Languages {
    ptBr = 'pt-br',
    enUs = 'en-us'
}

export const formatAmount = (amount: Amount): string => {
    const symbol = {
        [CoinEnum.real]: 'R$',
        [CoinEnum.eth]: 'ETH',
        [CoinEnum.usd]: '$',
        [CoinEnum.usdc]: 'USDC',
        [CoinEnum.btc]: 'BTC',
        [CoinEnum.usdt]: 'USDT',
    }[amount.coin];

    if (!symbol)
        console.log(`formatAmount -> Symbol not fount for: ${amount.coin} coin`);

    const result = amount.value.toLocaleString(window.navigator.language);
    return `${symbol || ''} ${result}`;
};
