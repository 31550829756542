











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DoubleIcons extends Vue {
    @Prop()
    public primaryIcon!: string;

    @Prop()
    public secondaryIcon!: string;
}
