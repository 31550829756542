






import { Component, Vue } from "vue-property-decorator";

@Component({
    name: 'twitterIcon'
})
export default class TwitterIcon extends Vue {

}
