

































import { Component, Vue } from 'vue-property-decorator';
import FacebookIcon from '@/components/utils/icons/Facebook.vue';
import InstagramIcon from '@/components/utils/icons/Instagram.vue';
import LinkedinIcon from '@/components/utils/icons/Linkedin.vue';
import TwitterIcon from '@/components/utils/icons/Twitter.vue';

@Component({
  components: { TwitterIcon, LinkedinIcon, InstagramIcon, FacebookIcon },
})
export default class Cover extends Vue {
}
