






import { Component, Vue } from "vue-property-decorator";

@Component({
    name: 'linkedinIcon'
})
export default class LinkedinIcon extends Vue {

}
