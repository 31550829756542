











import { Component, Vue } from 'vue-property-decorator';
import Cover from '@/components/Cover.vue';
import Quotes from '@/components/Quotes.vue';
import DownloadTheApp from '@/components/DownloadTheApp.vue';
import Mission from '@/components/Mission.vue';
import FAQ from '@/components/FAQ.vue';
import Footer from '@/components/Footer.vue';

@Component({
  components: {
    Footer,
    FAQ,
    Mission,
    DownloadTheApp,
    Quotes,
    Cover,
  },
})
export default class App extends Vue {
}
