import { render, staticRenderFns } from "./DetailedInformation.vue?vue&type=template&id=424c1958&scoped=true&"
import script from "./DetailedInformation.vue?vue&type=script&lang=ts&"
export * from "./DetailedInformation.vue?vue&type=script&lang=ts&"
import style0 from "./DetailedInformation.vue?vue&type=style&index=0&id=424c1958&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "424c1958",
  null
  
)

export default component.exports