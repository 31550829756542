







import { Component, Prop, Vue } from 'vue-property-decorator';
import { CoinEnum } from "@/models/coin";
import { Getter } from "vuex-class";

@Component({})
export default class CoinInitialsAndRate extends Vue {
    @Prop()
    public coin!: CoinEnum;

    @Prop()
    public initials!: string;

    @Getter('getRate')
    public getRate!: (coin: CoinEnum) => number | undefined;

    public get isUp(): boolean{
        return (this.rate || 0) > 0;
    }

    public get isDown(): boolean{
        return (this.rate || 0) < 0;
    }

    public get rate(): string | undefined{
        const result = this.getRate(this.coin)?.toLocaleString();

        if(!result || !parseFloat(result))
            return;

        return result;
    }
}
