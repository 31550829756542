














import { Component, Prop, Vue } from 'vue-property-decorator';
import DoubleIcons from "@/components/utils/DoubleIcons.vue";

@Component({
    components: { DoubleIcons }
})
export default class DetailedInformation extends Vue {
    @Prop()
    public primaryIcon!: string;

    @Prop()
    public secondaryIcon!: string;

    @Prop()
    public title!: string;

    @Prop()
    public text!: string;

    @Prop()
    public isActive!: boolean;
}
