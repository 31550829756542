






















import { Component, Prop, Vue } from 'vue-property-decorator';

enum StoreEnum{
    googlePlayStore = 1,
    appleStore = 2,
}

@Component({})
export default class DownloadTheAppCallToAction extends Vue {
    @Prop()
    public image!: string;

    private selectedStore: StoreEnum | null = null;

    public selectStore(store: StoreEnum): void{
        this.selectedStore = store;
    }
}
