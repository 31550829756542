























import { Component, Vue } from 'vue-property-decorator';
import FacebookIcon from "@/components/utils/icons/Facebook.vue";
import InstagramIcon from "@/components/utils/icons/Instagram.vue";
import LinkedinIcon from "@/components/utils/icons/Linkedin.vue";
import TwitterIcon from "@/components/utils/icons/Twitter.vue";

@Component({
    components: { TwitterIcon, LinkedinIcon, InstagramIcon, FacebookIcon }
})
export default class Footer extends Vue {
    public readonly address = "Av. das Nações Unidas, 14401 Torre C2 Tarumã, Conj. 303 - São Paulo - SP"
    public readonly addressLink = "https://www.google.com/maps/place/GrootBit+-+Av+Na%C3%A7%C3%B5es+Unidas+,14401+Condom%C3%ADnio+Parque+da+Cidade+-+Torre+Tarum%C3%A3,+303+-+Ch%C3%A1cara+Santo+Ant%C3%B4nio+(Zona+Sul),+S%C3%A3o+Paulo+-+SP,+04794-000/@-23.6246634,-46.7022496,15z/data=!4m2!3m1!1s0x94ce51ae0ab070fb:0x7424080f54d1cb11?hl=pt-br"
    public readonly document = "CNPJ 43.048.584/0001-88";
}
