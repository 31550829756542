







import { Component, Vue } from "vue-property-decorator";

@Component({
    name: 'facebookIcon'
})
export default class FacebookIcon extends Vue {
}
