import Vue from 'vue'
import Vuex, { GetterTree } from 'vuex'
import Decimal from "decimal.js";
import * as io from 'socket.io-client';
import { Amount, Quote } from "@/models/Quote";
import { CoinEnum } from "@/models/coin";


export interface State {
  quotes: Quote[];
  lastQuotes: Quote[];
}

const getCoin = (x: Quote): CoinEnum => x.source.coin;
const getPredicate = (coin: CoinEnum) => (x: Quote) => getCoin(x) === coin;
const getQuote = (quotes: Quote[], coin: CoinEnum): Quote | undefined => {
  const quote = quotes.find(getPredicate(coin));
  if(!quote)
    return;

  return quote;
};

const getQuoteValue = (quotes: Quote[], coin: CoinEnum): Amount | undefined => getQuote(quotes, coin)?.target;

export const getters: GetterTree<State, State> = {
  getPrice: state => (coin: CoinEnum): Amount | undefined => getQuoteValue(state.quotes, coin),
  getRate: state => (coin: CoinEnum): number | undefined => {
    const currentQuote = getQuoteValue(state.quotes, coin);
    const lastCurrentQuote = getQuoteValue(state.lastQuotes, coin);

    if(!currentQuote || !lastCurrentQuote)
      return;

    return new Decimal(currentQuote.value).times(100).dividedBy(lastCurrentQuote.value).minus(100).toNumber();
  }
}

Vue.use(Vuex)

const store = new Vuex.Store<State>({
  state: {
    quotes: [],
    lastQuotes: []
  },
  mutations: {
    addQuote(state, newQuote: Quote){
      const coin = getCoin(newQuote);
      const currentQuote = getQuote(state.quotes, coin);

      if(!currentQuote){
        state.quotes = [...state.quotes, newQuote];
        return;
      }

      state.lastQuotes = [...state.lastQuotes.filter(x => getCoin(x) !== coin), currentQuote];
      state.quotes = [...state.quotes.filter(x => getCoin(x) !== coin), newQuote];
    }
  },
  actions: {
  },
  modules: {
  },
  getters
})

export default store;

const url = process.env.VUE_APP_WEBSOCKET;

const connection = (io as any).connect(url, {
  path: '/api/websocket/socket',
  reconnectionDelay: 0,
  forceNew: true,
  transports: ['websocket'],
});

connection.on('connect', () => {
  console.log(`WS: Connection to ${url} established`);
});

connection.on('disconnect', () => {
  console.log(`WS: Connection to ${url} was closed`);
});

connection.on('update-coin-price', (quote: any) => store.commit('addQuote', quote));
