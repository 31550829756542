






import { Component, Vue } from 'vue-property-decorator';
import { CoinData, coins } from "@/models/coin";
import QuoteItem from "@/components/QuoteItem.vue";

@Component({
    components: { QuoteItem }
})
export default class Quotes extends Vue {
    public allCoins: CoinData[] = coins;
}
