









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class CardImage extends Vue {
    @Prop()
    public image!: string;

    @Prop()
    public title!: string;
}
