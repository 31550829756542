






























import { Component, Vue } from 'vue-property-decorator';
import CardImage from "@/components/utils/CardImage.vue";

@Component({
    components: { CardImage }
})
export default class Mission extends Vue {
}
